@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&family=Roboto+Mono&display=swap');
@font-face {
  font-family: 'Spoof';
  src: url(fonts/spoof.otf) format('otf');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1 {
  font-family: 'Spoof', 'Poppins', sans-serif;
  line-height: 0.8;
}

h2 {
  font-family: 'Spoof', 'Poppins', sans-serif;
  line-height: 1.05;
}

h3 {
  font-family: 'Spoof', 'Poppins', sans-serif;
  line-height: 1.2;
}

h4 {
  font-family: 'Spoof', 'Poppins', sans-serif;
  line-height: 1.2;
}

body, input {
  font-family: 'Poppins', sans-serif;
  font-size: 11pt;
}

input {
  font-weight: 500;
  color: currentColor;
  font-weight: bold;
}

::placeholder {
  color: currentColor;
  opacity: 0.4;
  font-weight: bold;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

iframe {
  border: 0px
}

input {
  border: 0px;
  outline: none;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
  margin: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  text-align: inherit;
}