@keyframes fadeInSlideUp {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

.animate-in {
  animation: fadeInSlideUp 0.3s cubic-bezier(.89,.01,.15,.98) forwards;
}

.animate-in p {
  animation: fadeInSlideUp 0.4s cubic-bezier(.89,.01,.15,.98) forwards;
}

.animate-out {
  animation: fadeOut 0.3s ease forwards;
}